'use client'

import '@/app/style/globals.scss'
import * as React from 'react'
import { redirect } from 'next/navigation'
import { getCurrentUserIsLoggedIn } from '@/lib/features/currentUser/currentUserSlice'
import { useAppSelector } from '@/lib/store'
import { getIsReduxInitialized } from '@/lib/features/registry/registrySlice'

interface Props {
  readonly children: React.ReactNode
}

export default function PublicAuthRedirectMembersLayout({ children }: Props): React.ReactElement {
  const isLoggedIn = useAppSelector(getCurrentUserIsLoggedIn)
  const isReduxInitialized = useAppSelector(getIsReduxInitialized)

  React.useEffect(() => {
    if (isReduxInitialized && isLoggedIn) {
      redirect('/generate')
    }
  }, [isReduxInitialized, isLoggedIn])

  return <div>{children}</div>
}
